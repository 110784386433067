// import React, { useEffect, useState } from 'react'
import React from 'react'

import UserLayout from 'src/pages/common/UserLayout'

// import FallbackSpinner from 'src/components/Loading'

// fortawesome 아이콘 크기 오류 해결 *//
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import 'src/styles/globals.css'
import 'src/styles/app.scss'

// fortawesome 아이콘 크기 오류 해결 *//
config.autoAddCss = false
const App = ({ Component, pageProps }) => {
  React.useLayoutEffect = React.useEffect

  // const [loading, setLoading] = useState(true)

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(false)
  //   }

  //   fetchData()
  // }, [])

  if (typeof process.versions === 'undefined') process.versions = {}

  /* 404 page */
  // return <UserLayout>{loading ? <FallbackSpinner /> : <Component {...pageProps} />}</UserLayout>
  return (
    <UserLayout>
      <Component {...pageProps} />
    </UserLayout>
  )
}

App.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {}
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  return { pageProps }
}

export default App
