/** @format */
import React, { useState } from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'

import clsx from 'clsx'

import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

const useStyles = makeStyles({
  list: {
    width: 260
  },
  fullList: {
    width: 'auto'
  }
})

const UserHeader = observer(() => {
  const router = useRouter()

  const classes = useStyles()
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const mainMenu = [
    {
      title: '아이소 데이터 소개',
      link: '/about',
      icon: 'icon-home'
    },
    {
      title: '컴퓨터 비전 솔루션',
      link: '/about/vision',
      icon: 'icon-folder'
    },
    {
      title: '자연어 처리 솔루션',
      link: '/about/lang',
      icon: 'icon-folder'
    },
    {
      title: '이상 탐지 솔루션',
      link: '/about/fds',
      icon: 'icon-folder'
    },
    {
      title: 'CSV 데이터 분석 솔루션',
      link: '/about/csv',
      icon: 'icon-folder'
    }
  ]

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return false
    }

    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <ul className='absolute top-0 w-full px-3 pt-2'>
        <li className='px-3 py-6'>
          <img src='/images/header/aiso_data_logo.png' alt='logo' className='w-8/12' />
        </li>
        {mainMenu.map(menu => (
          <li className='px-3 py-4 text-sm' key={menu.title}>
            <button onClick={() => router.push(`${menu.link}`)}>
              <i className={`${menu.icon} mr-3`} />
              <span>{menu.title}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )

  return (
    <nav className='flex justify-between fixed top-0 bg-white shadow-md z-50 w-full'>
      {/* 모바일 헤더 */}
      <div className='block w-full flex items-center justify-between p-3 lg:hidden'>
        {['left'].map(anchor => (
          <React.Fragment key={anchor}>
            <button onClick={toggleDrawer(anchor, true)} className='pt-1 px-3'>
              <i className='icon-menu text-xl text-blue-500' />
            </button>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
        <Link href='/about' className='w-36'>
          <img src='/images/header/aiso_data_logo.png' alt='logo' />
        </Link>
      </div>

      {/* 데스크톱 헤더 */}
      <div className='hidden p-3 lg:flex flex-row items-center gap-20'>
        <Link href='/about' className='w-36 ml-4'>
          <img src='/images/header/aiso_data_logo.png' alt='logo' className='object-cover' />
        </Link>
        <ul className='flex gap-12 mb-0'>
          {mainMenu.map(menu => (
            <Link key={menu.title} href={menu.link}>
              <li
                className={`font-bold ${router.pathname === menu.link ? 'header-menu-active' : 'header-menu'}
            `}
              >
                <div>{menu.title}</div>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </nav>
  )
})

export default UserHeader
